import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <p><a parentName="p" {...{
        "href": "/blog"
      }}>{`MDX Blog`}</a></p>
    <h1 {...{
      "id": "custom-pragma"
    }}>{`Custom pragma`}</h1>
    <p><inlineCode parentName="p">{`MDXTag`}</inlineCode>{`, for those that aren’t aware, is a critical piece in the way
MDX replaces HTML primitives like `}<inlineCode parentName="p">{`<pre>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<h1>`}</inlineCode>{` with custom React
Components.  `}<a parentName="p" {...{
        "href": "/post/codeblocks-mdx-and-mdx-utils"
      }}>{`I’ve previously
written`}</a>{` about the way `}<inlineCode parentName="p">{`MDXTag`}</inlineCode>{`
works when trying to replace the `}<inlineCode parentName="p">{`<pre>`}</inlineCode>{` tag with a custom code
component.  `}<a parentName="p" {...{
        "href": "https://github.com/ChristopherBiscardi/gatsby-mdx/blob/00769a1b72455f40843cd2f09ee34fd63b009fb2/packages/mdx-utils/index.js"
      }}>{`mdx-utils`}</a>{`
contains the methodology for pulling the props around appropriately
through the `}<inlineCode parentName="p">{`MDXTag`}</inlineCode>{` elements that are inbetween `}<inlineCode parentName="p">{`pre`}</inlineCode>{` and `}<inlineCode parentName="p">{`code`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`exports.preToCodeBlock = preProps => {
  if (
    // children is MDXTag
    preProps.children &&
    // MDXTag props
    preProps.children.props &&
    // if MDXTag is going to render a <code>
    preProps.children.props.name === 'code'
  ) {
    // we have a <pre><code> situation
    const {
      children: codeString,
      props: {className, ...props}
    } = preProps.children.props

    return {
      codeString: codeString.trim(),
      language: className && className.split('-')[1],
      ...props
    }
  }
  return undefined
}
`}</code></pre>
    <p>{`So `}<inlineCode parentName="p">{`MDXTag`}</inlineCode>{` is a real Component in the middle of all of the other MDX
rendered elements.  All of the code is included here for reference.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React, {Component} from 'react'

import {withMDXComponents} from './mdx-provider'

const defaults = {
  inlineCode: 'code',
  wrapper: 'div'
}

class MDXTag extends Component {
  render() {
    const {
      name,
      parentName,
      props: childProps = {},
      children,
      components = {},
      Layout,
      layoutProps
    } = this.props

    const Component =
      components[\`\${parentName}.\${name}\`] ||
      components[name] ||
      defaults[name] ||
      name

    if (Layout) {
      return (
        <Layout components={components} {...layoutProps}>
          <Component {...childProps}>{children}</Component>
        </Layout>
      )
    }

    return <Component {...childProps}>{children}</Component>
  }
}

export default withMDXComponents(MDXTag)
`}</code></pre>
    <p><inlineCode parentName="p">{`MDXTag`}</inlineCode>{` is used in the `}<a parentName="p" {...{
        "href": "https://github.com/mdx-js/mdx/blob/e1bcf1b1a352c9728424b01c1bb5d62e450eb48d/packages/mdx/mdx-hast-to-jsx.js#L163-L165"
      }}>{`mdx-hast-to-jsx
conversion`}</a>{`,
which is the final step in the MDX AST pipeline.  Every renderable
element is wrapped in an `}<inlineCode parentName="p">{`MDXTag`}</inlineCode>{`, and `}<inlineCode parentName="p">{`MDXTag`}</inlineCode>{` handled rendering the
element later.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`return \`<MDXTag name="\${node.tagName}" components={components}\${
  parentNode.tagName ? \` parentName="\${parentNode.tagName}"\` : ''
}\${props ? \` props={\${props}}\` : ''}>\${children}</MDXTag>\`
`}</code></pre>
    <h2 {...{
      "id": "a-concrete-example"
    }}>{`A concrete example`}</h2>
    <p>{`The following MDX`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-mdx"
      }}>{`# a title

    and such

testing
`}</code></pre>
    <p>{`turns into the following React code`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`export default ({components, ...props}) => (
  <MDXTag name="wrapper" components={components}>
    <MDXTag name="h1" components={components}>{\`a title\`}</MDXTag>{' '}
    <MDXTag name="pre" components={components}>
      <MDXTag
        name="code"
        components={components}
        parentName="pre"
        props={{metaString: null}}
      >{\`and such \`}</MDXTag>
    </MDXTag>{' '}
    <MDXTag name="p" components={components}>{\`testing\`}</MDXTag>
  </MDXTag>
)
`}</code></pre>
    <p>{`resulting in the following HTML`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div>
  <h1>a title</h1>
  <pre>
    <code>and such</code>
  </pre>
  <p>testing</p>
</div>
`}</code></pre>
    <h2 {...{
      "id": "createelement"
    }}>{`createElement`}</h2>
    <p>{`With the new approach, the above MDX transforms into this new React code`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const layoutProps = {}
export default function MDXContent(props) => {
  return (
    <div name="wrapper" {...layoutProps}>
      <h1>{\`a title\`}</h1>
      <pre>
        <code parentName="pre">{\`and such\`}</code>
      </pre>
      <p>{\`testing\`}</p>
    </div>
  )
}

MDXContent.isMDXComponent = true
`}</code></pre>
    <p>{`Notice how now the React elements are plainly readable without
wrapping `}<inlineCode parentName="p">{`MDXTag`}</inlineCode>{`.`}</p>
    <p>{`Now that we’ve cleaned up the intermediary representation, we need to
make sure that we have the same functionality as the old
`}<inlineCode parentName="p">{`MDXTag`}</inlineCode>{`.  This is done through a custom `}<inlineCode parentName="p">{`createElement`}</inlineCode>{`
implementation.  Typically when using React, we use
`}<inlineCode parentName="p">{`React.createElement`}</inlineCode>{` to render the elements onscreen but this time
we’ll be using our own.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const React = require('react')
const {useMDXComponents} = require('@mdx-js/react')

const TYPE_PROP_NAME = '__MDX_TYPE_PLEASE_DO_NOT_USE__'

const DEFAULTS = {
  inlineCode: 'code',
  wrapper: 'div'
}

const MDXCreateElement = ({
  components: propComponents,
  __MDX_TYPE_PLEASE_DO_NOT_USE__,
  parentName,
  ...etc
}) => {
  const components = useMDXComponents(propComponents)
  const type = __MDX_TYPE_PLEASE_DO_NOT_USE__
  const Component =
    components[\`\${parentName}.\${type}\`] ||
    components[type] ||
    DEFAULTS[type] ||
    type

  return React.createElement(Component, etc)
}
MDXCreateElement.displayName = 'MDXCreateElement'

module.exports = function(type, props) {
  const args = arguments

  if (typeof type === 'string') {
    const argsLength = args.length

    const createElementArgArray = new Array(argsLength)
    createElementArgArray[0] = MDXCreateElement

    const newProps = {}
    for (let key in props) {
      if (hasOwnProperty.call(props, key)) {
        newProps[key] = props[key]
      }
    }

    newProps[TYPE_PROP_NAME] = type

    createElementArgArray[1] = newProps

    for (let i = 2; i < argsLength; i++) {
      createElementArgArray[i] = args[i]
    }

    return React.createElement.apply(null, createElementArgArray)
  }

  return React.createElement.apply(null, args)
}
`}</code></pre>
    <h2 {...{
      "id": "vue"
    }}>{`Vue`}</h2>
    <p>{`One really cool application of the new output format using a custom
`}<inlineCode parentName="p">{`createElement`}</inlineCode>{` is that we can now write versions of it for Vue and
other frameworks.  Since the pragma insertion is the responsibility of
the webpack (or other bundlers) loader, swapping the pragma can be an
option in mdx-loader as long as we have a Vue `}<inlineCode parentName="p">{`createElement`}</inlineCode>{` to point
to.`}</p>
    <hr></hr>
    <p>{`Written by `}<a parentName="p" {...{
        "href": "https://christopherbiscardi.com"
      }}>{`Chris Biscardi`}</a></p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "/blog"
        }}>{`<`}{` Back to blog`}</a></strong></p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      